<template>
    <div>
        <b-container fluid id="data-production">
            <b-row>
                <b-col sm="12" class="text-center m-auto">
                    <h1>Data Protection</h1>
                </b-col>
                <b-col sm="12">
                    <div id="page_1">
                        <div id="id1_1">
                            <p class="paragraph">When you visit our website, we collect some information related to
                                your device, your browser and to the way you navigate our content, based on your
                                free and informed consent.</p>
                            <p class="paragraph">We will use this information to help us manage our website and
                                improve our marketing and communication strategy.</p>
                            <p class="paragraph">Because some of this information may ultimately relate to you, it
                                is collected, handled and secured in accordance with the General Data Protection
                                Regulation, Regulation(EU) 2016/679 of 27 April 2016 and Information Technology
                                Act, 2000 read along with Information Technology (Reasonable security practices
                                and procedures and sensitive personal data or information) Rules, 2011.</p>

                            <p class="p4 ft4">This statement applies to the visitors (“visitors”, “you”) of our
                                website."</p>
                            <p class="sub-heading">what information will we collect?</p>
                        </div>
                        <div id="id1_2">
                            <p class="paragraph">1)When you visit our website, we gather information that relates
                                to your device, your browser and to the way you navigate our website content,
                                such as:</p>
                            <p class="p7 ft4">● The Internet Protocol (IP) Address Of Your Device</p>
                        </div>
                    </div>
                    <div id="page_2">
                        <p class="p8 ft4"><span class="ft4">●</span><span class="ft7">The IP Address Of Your Internet Service Provider</span>
                        </p>
                        <p class="p9 ft4"><span class="ft4">●</span><span
                            class="ft7">Device Screen Resolution;</span></p>
                        <p class="p10 ft9"><span class="ft4">●</span><span class="ft8">Device Type (Unique Device Identifiers), Browser And Operating System Versions;</span>
                        </p>
                        <p class="p11 ft4"><span class="ft4">●</span><span class="ft7">Geographic Location (Country Only);</span>
                        </p>
                        <p class="p9 ft4"><span class="ft4">●</span><span
                            class="ft7">Preferred Language Used To Display</span>
                        </p>
                        <p class="p9 ft4"><span class="ft4">●</span><span class="ft7">The Date And Time Of Access To The Website</span>
                        </p>
                        <p class="p12 ft9"><span class="ft4">●</span><span class="ft8">The Internet Address From Which You Were Directly Linked Through To Our Website, The Control System That You Use</span>
                        </p>
                        <p class="p13 ft4"><span class="ft4">●</span><span class="ft7">The Parts Of The Website That You Visit</span>
                        </p>
                        <p class="p14 ft9"><span class="ft4">●</span><span class="ft8">The Pages Of The Website That You Have Visited And The Information That You Viewed</span>
                        </p>
                        <p class="p11 ft4"><span class="ft4">●</span><span
                            class="ft7">The Hyperlinks You Have Clicked</span>
                        </p>
                        <p class="p15 ft9"><span class="ft4">●</span><span class="ft8">Information On The Types Of Appliance That You Used For Your Visit To The Site</span>
                        </p>
                        <p class="p16 ft4"><span class="ft4">●</span><span class="ft7">The Material That You Send To Or Download From Our Website</span>
                        </p>
                        <p class="paragraph"><span class="ft4">2)</span><span class="ft10">If you choose to download our reports or white papers; or to subscribe to our newsletter news, events and alerts; or submit an inquiry we may ask you to fill out a form with information such as your name, </span>
                            <NOBR>e-mail</NOBR>
                            address, job title and company. From this point, we will be able to relate the
                            information listed above
                        </p>
                    </div>
                    <div id="page_3">
                        <p class="paragraph">about your device, your browser and to the way you navigate our
                            website content directly to you.</p>
                        <p class="sub-heading">for what purposes will we use the information related to our website
                            visitors?</p>
                        <p class="paragraph">We will use information that relates to your device, your browser and
                            to the way you navigate our website content primarily to manage the website and for
                            system administration purposes.</p>
                        <p class="paragraph">We will use this information for web analytics, to help us make
                            decisions related to the content we present and how we present it (for instance, we
                            can present you with content that relates to HouseOfBanquet in the country from where you
                            are accessing our website), in order to improve our website and provide our visitors
                            with a better browsing experience.</p>
                        <p class="paragraph">If you choose to download our reports or white papers or to
                            subscribe
                            to news, events and alerts, we will use the information you provide us to send you
                            the content requested, to communicate with you (including, where you agree, to send
                            you related information that might be of your interest) and to improve our marketing
                            and communication strategy.</p>
                    </div>
                    <div id="page_4">


                        <p class="paragraph">If you choose to submit an inquiry, we will use the information you
                            provide us to follow up on your request.</p>
                        <p class="sub-heading">On what legal basis will we process information related to our
                            visitors?</p>
                        <p class="paragraph">We will only collect and further process information related to you
                            based on your informed consent and on our legitimate interest in improving our
                            marketing and communications strategy.</p>
                        <p class="sub-heading">how we will handle your information?</p>
                        <p class="paragraph">To help us collecting and analysing information related to the way
                            our visitors use our website we use cookies and web analytics tools. We will use
                            this information on an aggregated level (in a way that can no longer be related back
                            to you) unless you choose to download our reports or white papers or subscribe to
                            our newsletter, in which case we will be able to relate the information about your
                            device, your browser and to the way you navigate our website content directly to you
                            we will use the information you provide us to.</p>
                        <p class="paragraph">We and our service providers have various security measures in place
                            to protect the information we get when you browse our website as well as the
                            information you provide us when downloadour reports or white papers; or to register
                            to news, events and alerts; or submit an inquiry. For example, we use encryption,
                            firewalls,</p>
                    </div>
                    <div id="page_5">


                        <div id="id5_1">
                            <p class="paragraph">intrusion detection software and manual security procedures to
                                help protect the accuracy and security of the data we hold. However, the
                                Internet is not 100% secure, and therefore we cannot promise that the
                                transmission of data over the Internet will always be secure. We, therefore,
                                encourage you to use caution when using the Internet.</p>
                            <p class="sub-heading">for how long we will keep your information</p>
                            <p class="paragraph">The information we collect related to your device, your browser
                                and to the way you navigate our website content for web analysis purposes is
                                only kept on an aggregated level. This means that once collected, this
                                information is combined with other related information in a way that it is no
                                longer possible to identify the person to whom it relates to anymore.</p>
                            <p class="paragraph">The information you provide us when you download our reports or
                                white papers or when you register to news, events and alerts is kept for 2 years
                                after last contact.</p>
                            <p class="paragraph">However, information related to some inquiries (such as those
                                related to compliance) may be kept for a longer retention period subject to
                                applicable law.</p>
                        </div>
                        <div id="id5_2">
                            <p class="sub-heading">with whom we will share your information</p>
                        </div>
                    </div>
                    <div id="page_6">


                        <p class="paragraph">We may share your information with other HouseOfBanquet Group companies
                            within the framework of HouseOfBanquet’s global brand and marketing strategy.Our third
                            parties service providers who carry out certain tasks on our behalf and according to
                            our instructions may also have access to information related to our website
                            visitors, to the extent necessary to provide us with their services. These third
                            parties include our IT service providers, which carry out tasks in the fields of
                            hosting, database management, website management, maintenance services, alert
                            services, web analyses and the handling of incoming inquiries and data analyses.</p>
                        <p class="paragraph">Our website uses analytics tools provided by specialised third
                            parties to help us diagnose technical problems, analyse the traffic to our website,
                            analyse trends, observe and measure how our visitors engage with our website.</p>
                        <p class="paragraph">HouseOfBanquet Group Companies, our third parties services providers and
                            the analytics tools providers we use may have access to the information related to
                            your device, your browser and to the way you navigate our content in accordance with
                            this statement from different countries, including in countries outside the European
                            Union.</p>
                    </div>
                    <div id="page_7">


                        <p class="paragraph">We have put in place appropriate safeguards to ensure your personal
                            data is adequately protected.</p>
                        <p class="sub-heading">your data protection rights</p>
                        <p class="p29 ft4">You may, at any time, request us to:</p>
                        <p class="p30 ft4"><span class="ft4">●</span><span class="ft7">Review The Information We Hold About You;</span>
                        </p>
                        <p class="p9 ft4"><span class="ft4">●</span><span class="ft7">Correct This Information If It Is Factually Wrong;</span>
                        </p>
                        <p class="p31 ft9"><span class="ft4">●</span><span class="ft8">Erase Your Personal Data Or Restrict The Processing Concerning Of Personal Data Concerning To You;</span>
                        </p>
                        <p class="p32 ft9"><span class="ft4">●</span><span class="ft8">Receive The Personal Data Which You Have Provided Us Via The Forms, In A Structured, Commonly Used And </span>
                            <NOBR>Machine-Readable</NOBR>
                            Format
                        </p>
                        <p class="paragraph">We will deal with your request with special care to ensure your
                            rights can be exercised effectively. We may ask you for proof of identity to ensure
                            that we are not sharing your personal data with anyone else but yourself!</p>
                        <p class="paragraph">You must be aware that, in particular cases (for instance, due to
                            legal requirements) we may not be able to make your request effective right
                            away.</p>
                        <p class="paragraph">In any case, within one month from your request, we will inform you
                            on the actions taken.</p>
                    </div>
                    <div id="page_8">


                        <div id="id8_1">
                            <p class="p21 ft4">You have the right to lodge a complaint with a supervisory data
                                protection authority.</p>
                            <p class="sub-heading">how you can withdraw your consent</p>
                            <p class="paragraph">If you register to receive news, events and alerts, or reports,
                                you can always withdraw your consent and
                                <NOBR>opt-out</NOBR>
                                from receiving further communications by sending an email to
                                info@houseofbanquets.com. You can prevent your browser to store cookies in your
                                browser settings.
                            </p>
                            <p class="p23 ft4">You can also delete the cookies from your device at any time via
                                the browser settings.</p>
                            <p class="paragraph">However, you must understand that withdrawing your consent will
                                not affect the lawfulness of processing based on consent before its withdrawal
                                nor further processing of the same data under other legal basis such as contract
                                or compliance with a legal obligation to which HouseOfBanquet is subject.</p>
                            <p class="sub-heading">changes to the Data Protection Statement</p>
                        </div>
                        <div id="id8_2">
                            <p class="paragraph">We may update the Data Protection Statement from time to time. You
                                can see the date on which the last change was made below in this statement. We
                                advise</p>
                        </div>
                    </div>
                    <div id="page_9">


                        <p class="paragraph">you to review our data protection statement on a regular basis so
                            that you are aware of any changes.</p>
                        <p class="sub-heading">contact us</p>
                        <p class="paragraph">If you have any questions about this policy or any privacy concerns,
                            or would like to exercise your rights, or obtain further information about the
                            safeguards we have in place so that your personal data is adequately protected when
                            transferred outside Europe, please contact us at <span
                                class="ft16">info@houseofbanquets.com</span>
                        </p>
                        <p class="p38 ft4">This statement was updated on: 20th July 2021.</p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>


<script>
    export default {
        data() {
            return {}
        },
    }
</script>


<style scoped>
    .paragraph {
        font: 18px 'Arial';
        line-height: 22px;
        margin-top: 10px;
    }

    #date-right p {
        font-size: 14px;
        font-weight: 400;
    }

    h1 {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
    }

    .paragraph {
        font-size: 16px;
        line-height: 25px;
    }

    p {
        line-height: 4;
    }


    .sub-heading {
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        margin-top: 10px;
    }

</style>
